import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { IdentityProvider } from '../../constants';
import { IdentityProviderRefreshTokenConfigMap } from '../../constants/identity-provider-refresh-token-config-map';

const clockSkewInSec = 60;

export function getDefaultSSOConfigs(): { [key in IdentityProvider]?: AuthConfig } {
    return {
        [IdentityProvider.SwissLife]: {
            issuer: environment.slSsoUrl,
            redirectUri: `${ window.location.origin }/en/logging-in`,
            clientId: environment.slSsoClient,
            responseType: 'code',
            scope: 'openid profile swissfex.api token-exchange.myclient',
            showDebugInformation: !environment.production,
            useSilentRefresh: false,
            clockSkewInSec
        },
        [IdentityProvider.DfsIdentityServer]: {
            issuer: environment.dfsIdentityServerUrl,
            redirectUri: `${ window.location.origin }/en/logging-in`,
            clientId: environment.dfsIdentityServerClient,
            responseType: 'code',
            scope: `openid profile${ IdentityProviderRefreshTokenConfigMap[IdentityProvider.DfsIdentityServer]?.isEnabled ? ' offline_access' : '' }`,
            postLogoutRedirectUri: `${ window.location.origin }/en/login`,
            useSilentRefresh: false,
            clockSkewInSec
        }
    };
}