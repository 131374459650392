import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'sfx-commons';
import { OFFER_CHANGED_STATUSES } from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { LENDER_ID_NAME_MAP } from '../../constants/lenderIdNameMap';
import { HttpService } from '../../core/services/http.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../../core/services/session-storage.service';
import { CollapsiblePanelId } from '../edit-project/offer-tranches-table/CollapsiblePaneld';
import { ProjectUtils } from '../edit-project/utils/project.utils';
import { BusinessType } from '../models/enums/businessType';
import { LenderName } from '../models/enums/lenderName';
import { OfferStatus } from '../models/enums/offerStatus';
import { OfferType } from '../models/enums/offerType';
import { ProcessCIndicatorType } from '../models/enums/processCIndicatorType';
import { ProjectStatus } from '../models/enums/projectStatus';
import { IExtendedOffer } from '../models/IExtendedOffer';
import { IGroupedOffers } from '../models/IGroupedOffers';
import { ILender } from '../models/ILender';
import { IOffer } from '../models/IOffer';
import { IOfferList } from '../models/IOfferList';
import { IProject } from '../models/IProject';
import { IRealTimeOfferStatusUpdateNotificationPayload } from '../models/IRealTimeOfferStatusUpdateNotificationPayload';
import { OfferSelectionState } from '../models/OfferSelectionState';

type OfferStatusAndTypeIndices = {
    financingConfirmationOfferIndex: number;
    fullKnockoutOfferIndex: number;
    preliminaryCheckOfferIndex: number;
    possibleOfferIndex: number;
    possibleOfferWithInterestRateIndex: number;
    canAdminSelectOffers: boolean;
    isAnyPreliminaryCheckOfferSelected: boolean;
};

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    public readonly cachedOffersObservable: Observable<IOfferList>;
    public readonly cachedGroupedOffersObservable: Observable<IGroupedOffers>;
    public readonly openedOfferObservable: Observable<IOffer>;
    public readonly isLoadingNotificationOffersObservable: Observable<boolean>;
    public readonly isOfferGenerationInProgressObservable: Observable<boolean>;
    public readonly offersStateObservable: Observable<OfferSelectionState>;
    public readonly isOfferSelectionInProgressObservable: Observable<boolean>;
    public readonly cachedLendersForTenantObservable: Observable<Array<ILender>>;
    public readonly signalGetGroupedOffersObservable: Observable<void>;

    private readonly defaultActiveOfferDetailsCollapsiblePanelIds = [CollapsiblePanelId.Overview, CollapsiblePanelId.TranchesOverview, CollapsiblePanelId.LenderDetails];
    private readonly cachedOffersSubject = new BehaviorSubject<IOfferList>(undefined);
    private readonly cachedGroupedOffersSubject = new BehaviorSubject<IGroupedOffers>(undefined);
    private readonly openedOfferSubject = new BehaviorSubject<IOffer>(undefined);
    private readonly isLoadingNotificationOffersSubject = new BehaviorSubject<boolean>(false);
    private readonly isOfferGenerationInProgressSubject = new BehaviorSubject<boolean>(false);
    private readonly offersStateSubject = new BehaviorSubject<OfferSelectionState>({});
    private readonly isOfferSelectionInProgressSubject = new BehaviorSubject<boolean>(false);
    private readonly cachedLendersForTenantSubject = new BehaviorSubject<Array<ILender>>(null);
    private readonly activeOfferDetailsCollapsiblePanelIdsSubject = new BehaviorSubject<Array<CollapsiblePanelId>>(undefined);
    private readonly signalGetGroupedOffersSubject = new Subject<void>();

    private _lastOfferGenerationDate: Date;

    constructor(private readonly httpService: HttpService, private readonly sessionStorageSvc: SessionStorageService) {
        this.cachedOffersObservable = this.cachedOffersSubject.asObservable();
        this.cachedGroupedOffersObservable = this.cachedGroupedOffersSubject.asObservable();
        this.openedOfferObservable = this.openedOfferSubject.asObservable();
        this.isLoadingNotificationOffersObservable = this.isLoadingNotificationOffersSubject.asObservable();
        this.isOfferGenerationInProgressObservable = this.isOfferGenerationInProgressSubject.asObservable();
        this.offersStateObservable = this.offersStateSubject.asObservable();
        this.isOfferSelectionInProgressObservable = this.isOfferSelectionInProgressSubject.asObservable();
        this.cachedLendersForTenantObservable = this.cachedLendersForTenantSubject.asObservable();
        this.signalGetGroupedOffersObservable = this.signalGetGroupedOffersSubject.asObservable();

        const activeOfferDetailsCollapsiblePanelIds = sessionStorageSvc.get(SESSION_STORAGE_KEY.ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS) as Array<CollapsiblePanelId>;
        if (activeOfferDetailsCollapsiblePanelIds?.length) {
            this.activeOfferDetailsCollapsiblePanelIdsSubject.next(activeOfferDetailsCollapsiblePanelIds);
        } else {
            this.activeOfferDetailsCollapsiblePanelIdsSubject.next(this.defaultActiveOfferDetailsCollapsiblePanelIds);
            sessionStorageSvc.set(SESSION_STORAGE_KEY.ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS, this.defaultActiveOfferDetailsCollapsiblePanelIds);
        }
    }

    public setIsLoadingNotificationOffers(isLoadingNotificationOffers: boolean): void {
        this.isLoadingNotificationOffersSubject.next(isLoadingNotificationOffers);
    }

    public setIsOfferGenerationInProgress(isOfferGenerationInProgress: boolean): void {
        this.isOfferGenerationInProgressSubject.next(isOfferGenerationInProgress);
    }

    public get cachedGroupedOffers(): IGroupedOffers {
        return this.cachedGroupedOffersSubject.getValue();
    }

    public get lastOfferGenerationDate(): Date {
        return this._lastOfferGenerationDate;
    }

    public signalGetGroupedOffers(): void {
        this.signalGetGroupedOffersSubject.next();
    }

    public calculateOfferActionFlags(offer: IOffer | IExtendedOffer, project: IProject, canAdminSelectOffers: boolean):
        { isOfferAcceptable: boolean, isOfferSelectable: boolean,
            wasOfferRejectedByLender: boolean, wasOfferReviewedByLender: boolean, lenderName: LenderName } {
        let isOfferAcceptable = false;
        let isOfferSelectable = false;
        let wasOfferReviewedByLender = false;
        let wasOfferRejectedByLender = false;
        const lenderName = this.getLenderName(offer);

        if (offer) {
            isOfferSelectable = this.calculateIsOfferSelectable(offer, project, canAdminSelectOffers);

            wasOfferRejectedByLender = offer.status === OfferStatus.RejectedByLender;
            wasOfferReviewedByLender = OFFER_CHANGED_STATUSES.indexOf(offer.status) > -1;
            isOfferAcceptable = (project.projectStatus === ProjectStatus.SubmittedToLenders ||
                project.projectStatus === ProjectStatus.AllOffersReceived) &&
                wasOfferReviewedByLender && !wasOfferRejectedByLender &&
                ((lenderName === LenderName.CreditSuisse || lenderName === LenderName.UBS) ? true : !(offer as IExtendedOffer).isExpired) &&
                (offer as IExtendedOffer).isDossierComplete &&
                ((lenderName === LenderName.CreditSuisse || lenderName === LenderName.UBS) ? true : offer.isFixable);
        }

        return {
            lenderName,
            isOfferAcceptable,
            isOfferSelectable,
            wasOfferRejectedByLender,
            wasOfferReviewedByLender
        };
    }

    public getLenderName(offer: IOffer | IExtendedOffer): LenderName {
        return LENDER_ID_NAME_MAP[offer.lenderInfo.id];
    }

    public clearCache(): void {
        this.clearOfferListCache();
        this.clearGroupedOffersCache();
    }

    public clearOfferListCache(): void {
        this.saveOfferState({});
        this.saveToCache(undefined);
        this._lastOfferGenerationDate = null;
    }

    public clearGroupedOffersCache(): void {
        this.cachedGroupedOffersSubject.next(undefined);
    }

    public clearOffersCacheForProject(projectId: number): void {
        const offerList = this.cachedOffersSubject.getValue();
        if (offerList?.offers?.length) {
            this.saveToCache({
                projectId,
                offers: [],
                areTranchesValid: true,
                isPayoutDateValid: true,
                projectStatusImplications: undefined
            });
        }
    }

    public async generateOffersForProject(projectId: number): Promise<IOfferList> {
        this.clearOfferListCache();
        this._lastOfferGenerationDate = new Date();
        const offerList = await this.httpService
            .post<IOfferList>(ROUTE_ID.GENERATE_OFFERS, { projectId }, null, null, null, true);
        this.saveToCache(offerList);

        return offerList;
    }

    public async getOffersForProject(projectId: number): Promise<IOfferList> {
        this.clearOfferListCache();
        const offerList = await this.httpService
            .get<IOfferList>(null, ROUTE_ID.OFFERS, { projectId }, undefined, undefined, true);
        this.saveToCache(offerList);

        return offerList;
    }

    public async getGroupedOffers(projectId: number): Promise<IGroupedOffers> {
        this.clearGroupedOffersCache();
        const response = await this.httpService
            .get<IGroupedOffers>(null, ROUTE_ID.GROUPED_OFFERS, { projectId }, undefined, undefined, true);

        this.cachedGroupedOffersSubject.next(response);

        return response;
    }

    public async getAvailableLenders(): Promise<Array<ILender>> {
        const cachedLenders = this.cachedLendersForTenantSubject.getValue();
        if (cachedLenders?.length) {
            return cachedLenders;
        }
        const lenders = await this.httpService.get<Array<ILender>>(null, ROUTE_ID.AVAILABLE_LENDERS);
        this.cachedLendersForTenantSubject.next(lenders);
        return lenders;
    }

    public async toggleOfferSelection(id: number, projectId: number, isSelected: boolean): Promise<IOffer> {
        const updatedOffer = await this.httpService.patch<IOffer>(ROUTE_ID.OFFER_TOGGLE_SELECTION, undefined, {
            projectId,
            id,
            isSelected
        });

        this.updateCachedOffer(updatedOffer);
        return updatedOffer;
    }

    public async selectOffers(projectId: number, selectedOffers: Array<number>): Promise<IOfferList> {
        const offerList = await this.httpService.patch<IOfferList>(ROUTE_ID.OFFERS_SELECT, undefined,
            { id: projectId, selectedOffers });
        this.saveToCache(offerList);
        return offerList;
    }

    public setOpenedOffer(offer: IOffer): void {
        this.openedOfferSubject.next(offer);
    }

    public updateCachedOfferByLenderId(offer: IOffer, businessType: BusinessType): void {
        const cachedOfferList = this.cachedOffersSubject.getValue();

        if (cachedOfferList && cachedOfferList.projectId === offer.projectId) {
            cachedOfferList.offers.updateItem(offer, 'id');
            const cachedIndex = cachedOfferList.offers.findIndex(o => o.lenderInfo.id === offer.lenderInfo.id && o.groupOfferId === offer.groupOfferId);
            if (cachedIndex > -1) {
                cachedOfferList.offers[cachedIndex] = offer;
                const isExtensionOrIncreaseBusinessType = businessType === BusinessType.Extension || businessType === BusinessType.Increase;

                cachedOfferList.offers.sort((o1, o2) => {
                    if (isExtensionOrIncreaseBusinessType) {
                        if (o1.isCurrentLender && o2.isCurrentLender) {
                            return 0;
                        } else if (o1.isCurrentLender) {
                            return -1;
                        } else if (o2.isCurrentLender) {
                            return 1;
                        }
                    }

                    if (o1.status === OfferStatus.Knockout) {
                        if (o2.status === OfferStatus.Knockout) {
                            return o1.id - o2.id;
                        } else {
                            return 1;
                        }
                    } else if (o2.status === OfferStatus.Knockout) {
                        return -1;
                    }
                    if (o1.id === 0) {
                        if (o2.id === 0) {
                            return 0;
                        } else {
                            return 1;
                        }
                    } else if (o2.id === 0) {
                        return -1;
                    }

                    return o1.interestRate - o2.interestRate;
                });
                this.saveToCache(cachedOfferList);
            }
        }
    }

    public updateCachedOffers(offerList: IOfferList): void {
        const currentOffers = this.cachedOffersSubject.getValue();
        if (currentOffers && currentOffers.projectId === offerList.projectId || !currentOffers) {
            this.saveToCache(offerList);
        }
    }

    public updateCachedOfferStatus(offerNotification: IRealTimeOfferStatusUpdateNotificationPayload): void {
        const cachedOfferList = this.cachedOffersSubject.getValue();

        if (cachedOfferList && cachedOfferList.projectId === offerNotification.projectId) {
            const offer = cachedOfferList.offers.find(o => o.id === offerNotification.offerId);
            if (offer) {
                offer.status = offerNotification.status;
                this.saveToCache(cachedOfferList);
            }
        }
    }

    public async updateInterestRate(projectId: number, id: number): Promise<IExtendedOffer> {
        const offer = await this.httpService.patch<IExtendedOffer>(ROUTE_ID.OFFER_REFRESH_INTEREST_RATE, undefined,
            { projectId, id });

        const groupedOffers: IGroupedOffers = this.cachedGroupedOffersSubject.getValue();

        if (groupedOffers) {
            if (groupedOffers.acceptedOffer) {
                groupedOffers.acceptedOffer = offer;
            } else {
                const amendedOffers = [...groupedOffers.amendedOffers];
                amendedOffers.updateItem(offer, 'id');
                groupedOffers.amendedOffers = amendedOffers;
            }
            this.cachedGroupedOffersSubject.next(groupedOffers);
        }

        return offer;
    }

    private calculateIsOfferSelectable(offer: IOffer | IExtendedOffer, project: IProject, canAdminSelectOffers: boolean): boolean {
        const isProjectEditable = ProjectUtils.isProjectEditable(project);
        if (project.isProjectGroupFixed || !isProjectEditable || !offer.isSelectable) {
            return false;
        }

        if (canAdminSelectOffers) {
            return true;
        }

        if (offer.status === OfferStatus.Possible ||
            offer.status === OfferStatus.SelectedByAdvisor) {
            if (offer.offerType === OfferType.ImmediateClosure ||
                offer.offerType === OfferType.Submittable /* redundant isSingleSelect condition) */) {
                return true;
            }
        }

        if (offer.status === OfferStatus.Knockout && offer.showImmediateClosureFlag && canAdminSelectOffers) {
            return true;
        }

        return offer.offerType === OfferType.PreliminaryCheckSubmittable;
    }

    public saveOfferState(offerState: OfferSelectionState): void {
        this.offersStateSubject.next(offerState);
    }

    public setIsOfferSelectionInProgress(isOfferSelectionInProgress: boolean): void {
        this.isOfferSelectionInProgressSubject.next(isOfferSelectionInProgress);
    }

    private saveToCache(offerList: IOfferList) {
        this.cachedOffersSubject.next(offerList);
    }

    private updateCachedOffer(offer: IOffer) {
        const cachedOfferList = this.cachedOffersSubject.getValue();

        if (cachedOfferList && cachedOfferList.projectId === offer.projectId) {
            cachedOfferList.offers.updateItem(offer, 'id');
            this.saveToCache(cachedOfferList);
        }
    }

    public calculateOfferStatusAndTypeIndices(offerList: IOfferList, isBackofficeUser: boolean, isFinancingConfirmationFlowEnabled?: boolean): OfferStatusAndTypeIndices {
        let financingConfirmationOfferIndex = -1;
        let fullKnockoutOfferIndex = -1;
        let noOfferIndex = -1;
        let preliminaryCheckOfferIndex = -1;
        let possibleOfferIndex = -1;
        let possibleOfferWithInterestRateIndex = -1;
        let placeholderOfferIndex = -1;
        let canAdminSelectOffers = false;
        let isAnyPreliminaryCheckOfferSelected = false;

        for (let i = 0; i < offerList?.offers?.length; i++) {
            const offer = offerList.offers[i];
            if (offer.id === 0) {
                if (placeholderOfferIndex === -1) {
                    placeholderOfferIndex = i;
                }
            }
            if (offer.offerType === OfferType.NoOffer) {
                if (offer.status === OfferStatus.Knockout) {
                    if (noOfferIndex === -1) {
                        noOfferIndex = i;
                    }
                    if (!offer.showKnockoutAsValidOffer && fullKnockoutOfferIndex === -1) {
                        fullKnockoutOfferIndex = i;
                    }
                } else if (offer.status === OfferStatus.SelectedByAdvisor) {
                    if (noOfferIndex === -1) {
                        noOfferIndex = i;
                    }
                    if (offer.showImmediateClosureFlag) {
                        if (possibleOfferIndex === -1) {
                            possibleOfferIndex = i;
                        }
                    } else {
                        isAnyPreliminaryCheckOfferSelected = true;

                        if (preliminaryCheckOfferIndex === -1) {
                            preliminaryCheckOfferIndex = i;
                        }
                    }
                }
            } else if (offer.offerType === OfferType.PreliminaryCheckLight ||
                offer.offerType === OfferType.PreliminaryCheckSubmittable) {
                if (preliminaryCheckOfferIndex === -1) {
                    preliminaryCheckOfferIndex = i;
                }

                if (offer.status === OfferStatus.SelectedByAdvisor) {
                    isAnyPreliminaryCheckOfferSelected = true;
                }
            } else if (offer.offerType === OfferType.Light ||
                offer.offerType === OfferType.Submittable || // redundant isSingleSelect condition
                offer.offerType === OfferType.ImmediateClosure) {
                if (possibleOfferIndex === -1) {
                    possibleOfferIndex = i;
                }
                if (!isNullOrUndefined(offer.interestRate)) {
                    if (possibleOfferWithInterestRateIndex === -1) {
                        possibleOfferWithInterestRateIndex = i;
                    }
                }
                if (isFinancingConfirmationFlowEnabled) {
                    if (offer.offerIndicator === ProcessCIndicatorType.Exception ||
                        offer.offerIndicator === ProcessCIndicatorType.SubmittableOffer || // redundant isSingleSelect condition
                        offer.offerIndicator === ProcessCIndicatorType.ImmediateClosure) {
                        if (financingConfirmationOfferIndex === -1) {
                            financingConfirmationOfferIndex = i;
                        }
                    }
                }
            }
        }

        if (financingConfirmationOfferIndex > -1 && placeholderOfferIndex !== -1) {
            financingConfirmationOfferIndex = -1;
        }

        if (isBackofficeUser && !offerList?.projectStatusImplications?.missingFields?.length &&
            placeholderOfferIndex === -1) {
            canAdminSelectOffers = true;
        }

        return {
            financingConfirmationOfferIndex,
            fullKnockoutOfferIndex,
            preliminaryCheckOfferIndex,
            possibleOfferIndex,
            possibleOfferWithInterestRateIndex,
            canAdminSelectOffers,
            isAnyPreliminaryCheckOfferSelected
        };
    }

    public get activeOfferDetailsCollapsiblePanelIds() {
        return this.activeOfferDetailsCollapsiblePanelIdsSubject.getValue();
    }

    public updateActiveOfferDetailsCollapsiblePanelIds(isActive: boolean, collapsiblePanelId: CollapsiblePanelId) {
        if (!isNullOrUndefined(collapsiblePanelId)) {
            const currentActivePanelIds = this.activeOfferDetailsCollapsiblePanelIds;
            const isIncluded = currentActivePanelIds.includes(collapsiblePanelId);
            let updatedActivePanelIds: Array<CollapsiblePanelId> = null;

            if (isActive) {
                if (!isIncluded) {
                    updatedActivePanelIds = currentActivePanelIds.concat(collapsiblePanelId);
                }
            } else {
                if (isIncluded) {
                    updatedActivePanelIds = currentActivePanelIds.filter(i => i !== collapsiblePanelId);
                }
            }

            if (updatedActivePanelIds) {
                this.activeOfferDetailsCollapsiblePanelIdsSubject.next(updatedActivePanelIds);
                this.sessionStorageSvc.set(SESSION_STORAGE_KEY.ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS, updatedActivePanelIds);
            }
        }
    }

    public cancelGenerateOffersRequest(customRequestCancellationError?: string): void {
        this.httpService.cancelRequest(ROUTE_ID.GENERATE_OFFERS, 'POST', null, customRequestCancellationError);
    }
}
